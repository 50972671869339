export class AppRoute {
  public static login(): string {
    return '/login';
  }
  public static epics(): string {
    return '/epics';
  }

  public static epic(epicId?: string): string {
    return `/epic${epicId ? '/' + epicId : ''}`;
  }

  public static story(epicId: string, storyId?: string): string {
    return `${this.epic(epicId)}/story${storyId ? '/' + storyId : ''}`;
  }
}
