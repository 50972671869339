import 'firebase/auth';

import { auth } from 'firebase/app';

import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AppRoute } from '@shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private authService: AngularFireAuth, private router: Router) {
  }

  async login() {
    const userCredential = await this.authService.signInWithPopup(new auth.GoogleAuthProvider());
    if (userCredential.user) {
      this.router.navigateByUrl(AppRoute.epics());
    }
  }
}
