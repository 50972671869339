import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, customClaims } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { AppRoute, Role } from './shared/constants';

const redirectAuthenticated = () =>
  pipe(
    customClaims,
    map((token) =>
      token?.claims?.role === Role.ADMIN ? [AppRoute.epics()] : true
    )
  );
const adminOnly = () =>
  pipe(
    customClaims,
    map((token) =>
      token?.claims?.role === Role.ADMIN ? true : [AppRoute.login()]
    )
  );

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectAuthenticated },
  },
  {
    path: '',
    loadChildren: () =>
      import('./portal/portal.module').then((m) => m.PortalModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: adminOnly },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
