export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyABN5Y9TagQo8dVbfqCAi8p2YKnSw67aWs',
  authDomain: 'zakar-app.firebaseapp.com',
  databaseURL: 'https://zakar-app.firebaseio.com',
  projectId: 'zakar-app',
  storageBucket: 'zakar-app.appspot.com',
  messagingSenderId: '380299017568',
  appId: '1:380299017568:web:dea9bf3292f29db64c3205',
  measurementId: 'G-CD4KWDHMJ2'
};
